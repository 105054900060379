import { Box, Container, Grid } from "theme-ui";
import { Navigation } from "../../components/navigation/Navigation";
import { theme } from "../../theme";
import { Autopilot } from "./Autopilot";
import { Supercharging } from "./Supercharging";
import { TeslaServiceCenter } from "./TeslaServiceCenter";
import { Footer } from "../../components/footer/Footer";
import { H1 } from "../../components/typography";

export const UsefulInformation = () => {
  return (
    <Box>
      <Navigation />
      <Container sx={{ maxWidth: theme.sizes.container }} my={[theme.space["6"], theme.space["12"]]}>
        <Box sx={{ textAlign: "center" }} mb={[theme.space["6"], theme.space["12"]]}>
          <H1>Die wichtigsten Begriffe rund um Tesla - kurz & einfach erklärt</H1>
        </Box>
        <Grid gap={theme.space["8"]} columns={["1fr", "1fr", "1fr 1fr 1fr"]} sx={{ textAlign: "center" }}>
          <Autopilot />
          <Supercharging />
          <TeslaServiceCenter />
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};
