import { Box, Container } from 'theme-ui';
import { Carousel } from 'react-responsive-carousel';
import { Footer } from '../../components/footer/Footer';
import { H2 } from '../../components/typography';
import { Navigation } from '../../components/navigation/Navigation';
import { Services } from './Services';
import { theme } from '../../theme';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const Home = () => {
  return (
    <Box>
      <Navigation />
      <Box
        sx={{
          background: "url(/images/home2.jpg) no-repeat center / cover fixed",
          height: [400, 600],
        }}
      ></Box>
      <Container
        sx={{ maxWidth: theme.sizes.xsContainer }}
        px={theme.space["4"]}
        my={[theme.space["6"], theme.space["8"], theme.space["10"]]}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box mb={[theme.space["4"], theme.space["6"], theme.space["8"]]} sx={{ textAlign: "center" }}>
            <H2 color={theme.colors.red["800"]}>WILLKOMMEN</H2>
          </Box>
          <Box sx={{ fontSize: ["16px", "20px", "22px"] }}>
            Wir von Project-E sind sind die Experten für Tesla Gebrauchtwagen, Reparaturen, Upgrades und Zubehör.
          </Box>
        </Box>
      </Container>
      <Container sx={{ maxWidth: theme.sizes.smContainer }} pb={theme.space["4"]}>
        <Carousel>
          <Box>
            <img src="/images/tesla-s90d.webp" alt={"Tesla Model S90D"} />
            <p className="legend">Tesla Model S90D</p>
          </Box>
          <Box>
            <img src="/images/tesla-3-sr.webp" alt={"New Model 3 SR+"} />
            <p className="legend">New Model 3 SR+</p>
          </Box>
          <Box>
            <img src="/images/tesla-s75d.webp" alt={"Tesla Model S 75D FACELIFT"} />
            <p className="legend">Tesla Model S 75D FACELIFT</p>
          </Box>
          <Box>
            <img src="/images/tesla-s85d.webp" alt={"Tesla Model S85D - Free Supercharging"} />
            <p className="legend">Tesla Model S85D - Free Supercharging</p>
          </Box>
        </Carousel>
      </Container>
      <Container sx={{ maxWidth: theme.sizes.container }} my={[theme.space["4"], theme.space["6"], theme.space["8"]]}>
        <Box mb={[theme.space["6"], theme.space["8"], theme.space["12"]]} sx={{ textAlign: "center" }}>
          <H2 color={theme.colors.red["800"]}>DIENSTLEISTUNGEN VON PROJECT-E</H2>
        </Box>
        <Services />
      </Container>
      <Footer />
    </Box>
  );
};
