import { Card, CardContent, CardHeader, CardMedia } from "@mui/material";
import { Box } from "theme-ui";

export const Supercharging = () => {
  return (
    <Card>
      <CardHeader title={"Supercharging"} />
      <CardMedia sx={{ paddingBottom: "60%", position: "relative" }}>
        <Box sx={{ position: "absolute", inset: 0 }}>
          <img
            src={"/images/tesla-terms/2.webp"}
            alt={""}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
      </CardMedia>
      <CardContent>
        <Box>
          Beschreibt das Laden an spezifischen Teslaladestellen. An besagten Ladestellen lädt ihr Tesla besonders
          schnell und alte Modelle teilweise sogar kostenlos. Das Tesla Superchargingnetzwerk ist praktisch in der
          ganzen westlichen Welt präsent und war bis Anfang 2022 exklusiv nur von Teslas nutzbar. Mittlerweile wurde die
          Nutzung in bestimmten Ländern für alle Marken freigegeben.
        </Box>
      </CardContent>
    </Card>
  );
};
