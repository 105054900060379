import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Looks1Icon from "@mui/icons-material/LooksOne";
import Looks2Icon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import { Box, Container, Flex, Grid } from "theme-ui";
import { Chip, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import { Navigation } from "../../components/navigation/Navigation";
import { TextOverlayImage } from "../../components/ui/TextOvarlayImage";
import { theme } from "../../theme";
import { Footer } from "../../components/footer/Footer";
import { H1, H2 } from "../../components/typography";

export const RentTesla = () => {
  return (
    <Box>
      <Navigation />
      <TextOverlayImage src="/images/rent-tesla.webp" height={[48, 32, 18]}>
        <Box sx={{ textAlign: "center", fontSize: ["18px", "26px", "34px"], fontWeight: "bold" }}>
          TESLA MIETEN AB CHF 50.- PRO TAG
        </Box>
      </TextOverlayImage>
      <Container
        px={theme.space["3"]}
        my={[theme.space["6"], theme.space["12"]]}
        sx={{ maxWidth: theme.sizes.smContainer }}
      >
        <Box>
          <Box sx={{ textAlign: "center" }} mb={[theme.space["6"], theme.space["12"]]}>
            <H1 color={theme.colors.red["800"]}>Ob überlange Probefahrt oder Urlaub - mieten Sie Ihren Tesla</H1>
          </Box>
          <Box>
            <Grid gap={theme.space["6"]} columns={["1fr", "1fr", "1fr 2fr"]} mb={theme.space["2"]}>
              <Box>
                <img src={"/images/tesla-3-sr.webp"} alt="tesla" />
              </Box>
              <Box>
                <Box mb={theme.space["4"]}>
                  <Flex sx={{ justifyContent: "space-between" }}>
                    <Box mb={theme.space["1"]}>
                      <Typography variant="h6">MODEL 3 STANDARD RANGE MIETEN</Typography>
                    </Box>
                    <Box sx={{ fontWeight: 700 }}>Ab CHF 99.-</Box>
                  </Flex>
                  <Box>
                    <Chip label={"REICHWEITE 330 KM"} variant="outlined" />
                  </Box>
                </Box>
                <Box>
                  Mieten Sie eines unsere Model 3 Standard Range - das Basismodell von Tesla - ideal für Ihre ersten
                  Erfahrungen mit der Elektromobilität
                </Box>
              </Box>
            </Grid>
            <Grid gap={theme.space["4"]} columns={["1fr", "1fr", "1fr 1fr"]}>
              <Box>
                <Paper variant="outlined">
                  <Box p={theme.space["4"]}>
                    <Box mb={theme.space["2"]}>
                      <h3>Preise</h3>
                    </Box>
                    <Box>
                      <List disablePadding>
                        <ListItem disableGutters divider>
                          <ListItemIcon sx={{ minWidth: "36px" }}>
                            <Looks1Icon />
                          </ListItemIcon>
                          <ListItemText primary="12 Stunden, inklusive 100 Kilometer" />
                          <ListItemText>
                            <Box sx={{ fontWeight: "bold", textAlign: "right" }}>CHF 99.-</Box>
                          </ListItemText>
                        </ListItem>
                        <ListItem disableGutters divider>
                          <ListItemIcon sx={{ minWidth: "36px" }}>
                            <Looks2Icon />
                          </ListItemIcon>
                          <ListItemText primary="24 Stunden inklusive 200 Kilometer" />
                          <ListItemText primary="CHF 179.-" sx={{ textAlign: "right" }} />
                        </ListItem>
                        <ListItem disableGutters divider>
                          <ListItemIcon sx={{ minWidth: "36px" }}>
                            <Looks3Icon />
                          </ListItemIcon>
                          <ListItemText primary="7 Tage inklusive 500 Kilometer" />
                          <ListItemText primary="CHF 499.-" sx={{ textAlign: "right" }} />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemIcon sx={{ minWidth: "36px" }}>
                            <Looks4Icon />
                          </ListItemIcon>
                          <ListItemText primary="30 Tage inklusive 2000 Kilometer" />
                          <ListItemText primary="CHF 1'499.-" sx={{ textAlign: "right" }} />
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box>
                <Paper variant="outlined">
                  <Box p={theme.space["4"]}>
                    <Box mb={theme.space["2"]}>
                      <h3>Inklusive</h3>
                    </Box>
                    <Box>
                      <List disablePadding>
                        <ListItem disableGutters divider>
                          <ListItemIcon sx={{ minWidth: "36px" }}>
                            <CheckIcon />
                          </ListItemIcon>
                          <ListItemText>Preise inklusive einer Batterieladung</ListItemText>
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemIcon sx={{ minWidth: "36px" }}>
                            <ErrorOutlineIcon />
                          </ListItemIcon>
                          <ListItemText>
                            Weitere Ladevorgänge müssen selbst bezahlt werden, kosten ungefähr CHF 10.- für eine
                            Vollladung
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Container
        px={theme.space["3"]}
        mb={[theme.space["6"], theme.space["12"]]}
        sx={{ maxWidth: theme.sizes.smContainer }}
      >
        <Box mb={[theme.space["6"], theme.space["12"]]} sx={{ textAlign: "center" }}>
          <H2 color={theme.colors.red["800"]}>Vorteile einer Teslamiete</H2>
        </Box>
        <Box>
          <Grid
            gap={theme.space["8"]}
            columns={["1fr", "1fr", "1fr 1fr"]}
            sx={{ alignItems: "center", columnGap: theme.space["12"], rowGap: theme.space["16"] }}
          >
            <Box>
              <Box>
                <img src="/images/tesla/3.webp" alt="" style={{ width: "100%" }} />
              </Box>
              <Box my={theme.space["4"]}>
                <H2>Günstig unterwegs</H2>
              </Box>
              <Box sx={{ fontSize: ["16px", "18px"] }}>
                Mit unseren Mietfahrzeugen sind Sie günstig unterwegs - nicht nur unsere Mietkonditionen sind äusserst
                günstig, nein, Sie profitieren zudem von günstigen Strompreisen und Fahrtkosen von nur knapp 5 Franken
                pro 100 Kilometer
              </Box>
            </Box>
            <Box sx={{ gridColumn: ["auto", "auto", "2 / 3"], gridRow: ["auto", "auto", "1 / 3"] }}>
              <Box>
                <img src="/images/tesla/4.webp" alt="" style={{ width: "100%" }} />
              </Box>
              <Box my={theme.space["4"]}>
                <H2>Hauslieferung möglich</H2>
              </Box>
              <Box sx={{ fontSize: ["16px", "18px"] }}>
                Sie können Ihren Mietwagen entweder bei uns in 8932 Mettmenstetten direkt am Bahnhof abholen oder wir
                liefern es gegen einen günstigen Pauschalbetrag zu Ihnen nach Hause - fragen Sie uns doch unverbindlich
                an
              </Box>
            </Box>
            <Box>
              <Box>
                <img src="/images/tesla/5.webp" alt="" style={{ width: "100%" }} />
              </Box>
              <Box my={theme.space["4"]}>
                <H2>CHF 200.- beim Kauf geschenkt!</H2>
              </Box>
              <Box sx={{ fontSize: ["16px", "18px"] }}>
                Sind Sie nach der Miete so von unserem Tesla begeistert, dass Sie sich für einen Kauf eines Teslas bei
                uns entscheiden, ziehen wir Ihnen CHF 200.- der Fahrzeugmiete vom Kaufpreis ab - Sie profitieren
                doppelt!
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};
