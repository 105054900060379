import { Box, Flex } from 'theme-ui';
import { Button } from '../../components/Button/Button';
import { TextOverlayImage } from '../../components/ui/TextOvarlayImage';
import { theme } from '../../theme';

export const Services = () => {
  return (
    <Flex sx={{ flexWrap: "wrap" }}>
      <Box sx={{ width: ["100%", "50%"], border: "1px solid white" }}>
        <TextOverlayImage src="/images/tesla-cars.webp">
          <Box px={[theme.space["6"], theme.space["12"], theme.space["16"]]} sx={{ textAlign: "center" }}>
            <Box sx={{ fontSize: ["18px", "26px", "34px"], fontWeight: "bold" }} mb={theme.space["4"]}>
              TESLA GEBRAUCHTWAGEN AN- UND VERKAUF
            </Box>
            <Box>
              <Button variant="primary" size="medium">
                Mehr erfahren
              </Button>
            </Box>
          </Box>
        </TextOverlayImage>
      </Box>
      <Box sx={{ width: ["100%", "50%"], border: "1px solid white" }}>
        <TextOverlayImage src="/images/vehicle-tuning.webp">
          <Box px={[theme.space["6"], theme.space["12"], theme.space["16"]]} sx={{ textAlign: "center" }}>
            <Box sx={{ fontSize: ["18px", "26px", "34px"], fontWeight: "bold" }} mb={theme.space["4"]}>
              FAHRZEUGTUNING UND ZUBEHÖR
            </Box>
            <Box>
              <Button variant="primary" size="medium">
                Mehr erfahren
              </Button>
            </Box>
          </Box>
        </TextOverlayImage>
      </Box>
      <Box sx={{ width: ["100%", "50%"], border: "1px solid white" }}>
        <TextOverlayImage src="/images/service-and-repair.webp">
          <Box px={[theme.space["6"], theme.space["12"], theme.space["16"]]} sx={{ textAlign: "center" }}>
            <Box sx={{ fontSize: ["18px", "26px", "34px"], fontWeight: "bold" }} mb={theme.space["4"]}>
              SERVICE- UND REPARATURARBEITEN
            </Box>
            <Box>
              <Button variant="primary" size="medium">
                Mehr erfahren
              </Button>
            </Box>
          </Box>
        </TextOverlayImage>
      </Box>
      <Box sx={{ width: ["100%", "50%"], border: "1px solid white" }}>
        <TextOverlayImage src="/images/tesla-cars.webp">
          <Box>
            <Box>Tesla used car buying and selling</Box>
            <Box>Button</Box>
          </Box>
        </TextOverlayImage>
      </Box>
    </Flex>
  );
};
