import styled, { css } from "styled-components";
import { theme } from "../../theme";

interface Heading {
  children: React.ReactNode;
  color?: string;
}

export const H1 = ({ children, color }: Heading) => {
  return <StyledH1 $color={color}>{children}</StyledH1>;
};

const commonStyle = css<{ $color?: string }>`
  font-weight: 500;
  color: ${(props) => props.$color || theme.colors.gray["900"]};
`;

const StyledH1 = styled("h1")<{ $color?: string }>`
  ${commonStyle}
  font-size: 32px;
  @media (max-width: ${theme.breakpoints[1]}) {
    font-size: 28px;
  }
  @media (max-width: ${theme.breakpoints[0]}) {
    font-size: 24px;
  }
`;

export const H2 = ({ children, color }: Heading) => {
  return <StyledH2 $color={color}>{children}</StyledH2>;
};

const StyledH2 = styled("h2")<{ $color?: string }>`
  ${commonStyle}
  font-size: 28px;
  @media (max-width: ${theme.breakpoints[1]}) {
    font-size: 24px;
  }
  @media (max-width: ${theme.breakpoints[0]}) {
    font-size: 20px;
  }
`;

export const H3 = ({ children, color }: Heading) => {
  return <StyledH3 $color={color}>{children}</StyledH3>;
};

const StyledH3 = styled("h3")<{ $color?: string }>`
  ${commonStyle}
  font-size: 24px;
  @media (max-width: ${theme.breakpoints[1]}) {
    font-size: 22px;
  }
  @media (max-width: ${theme.breakpoints[0]}) {
    font-size: 18px;
  }
`;

export const H4 = ({ children, color }: Heading) => {
  return <StyledH4 $color={color}>{children}</StyledH4>;
};

const StyledH4 = styled("h4")<{ $color?: string }>`
  ${commonStyle}
  font-size: 20px;
  @media (max-width: ${theme.breakpoints[1]}) {
    font-size: 18px;
  }
  @media (max-width: ${theme.breakpoints[0]}) {
    font-size: 16px;
  }
`;
