import { Box, Container, Grid } from "theme-ui";
import { DoorHandleRepairModelS } from "./DoorHandleRepairModelS";
import { Navigation } from "../../components/navigation/Navigation";
import { theme } from "../../theme";
import { PowerliftUpgradeTrunk } from "./PowerliftUpgradeTrunk";
import { RearSpoiler } from "./RearSpoiler";
import { LedTrunkLightning } from "./LedTrunkLightning";
import { InductionMobileCharging } from "./InductionMobileCharging";
import { Footer } from "../../components/footer/Footer";
import { H1, H2 } from "../../components/typography";
import { TextOverlayImage } from "../../components/ui/TextOvarlayImage";

export const Services = () => {
  return (
    <Box>
      <Navigation />
      <TextOverlayImage src="/images/services/banner.webp" height={[40, 20, 18]}>
        <Box sx={{ textAlign: "center" }} my={[theme.space["6"], theme.space["12"]]} px={theme.space["3"]}>
          <H1 color={theme.colors.white}>Unsere Dienstleistungen für Sie</H1>
        </Box>
      </TextOverlayImage>
      <Box mt={[theme.space["8"], theme.space["12"]]} mb={theme.space["4"]} px={theme.space["4"]} sx={{ textAlign: "center" }}>
        <H2>Fahrzeugreparaturen und Upgrades</H2>
      </Box>
      <Grid gap={[theme.space["4"], theme.space["4"], theme.space["8"]]} mb={theme.space["4"]}>
        <Box>
          <Container
            sx={{ maxWidth: theme.sizes.container }}
            py={[theme.space["3"], theme.space["12"]]}
            px={theme.space["3"]}
          >
            <DoorHandleRepairModelS />
          </Container>
        </Box>

        <Box backgroundColor={theme.colors.gray["100"]}>
          <Container
            sx={{ maxWidth: theme.sizes.container }}
            py={[theme.space["3"], theme.space["12"]]}
            px={theme.space["3"]}
          >
            <PowerliftUpgradeTrunk />
          </Container>
        </Box>

        <Box>
          <Container
            sx={{ maxWidth: theme.sizes.container }}
            py={[theme.space["3"], theme.space["12"]]}
            px={theme.space["3"]}
          >
            <RearSpoiler />
          </Container>
        </Box>

        <Box backgroundColor={theme.colors.gray["100"]}>
          <Container
            sx={{ maxWidth: theme.sizes.container }}
            py={[theme.space["3"], theme.space["12"]]}
            px={theme.space["3"]}
          >
            <LedTrunkLightning />
          </Container>
        </Box>

        <Box>
          <Container
            sx={{ maxWidth: theme.sizes.container }}
            py={[theme.space["3"], theme.space["12"]]}
            px={theme.space["3"]}
          >
            <InductionMobileCharging />
          </Container>
        </Box>
      </Grid>
      <Footer />
    </Box>
  );
};
