import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Container, Flex, Grid } from "theme-ui";
import { Collapse } from "@mui/material";
import { daysOfWeekLong } from "../../constants";
import { Footer } from "../../components/footer/Footer";
import { H1, H4 } from "../../components/typography";
import { Navigation } from "../../components/navigation/Navigation";
import { theme } from "../../theme";
import { useState } from "react";

const workingHours = [
  "9:00 AM - 7:00 PM",
  "9:00 AM - 7:00 PM",
  "9:00 AM - 7:00 PM",
  "9:00 AM - 7:00 PM",
  "9:00 AM - 7:00 PM",
  "9:00 AM - 5:00 PM",
  "By Appointment",
];

export const Contact = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box>
      <Navigation />
      <Container sx={{ maxWidth: theme.sizes.container }}>
        <Grid columns={"1fr 1fr"} gap={[theme.space["8"], theme.space["16"]]}>
          <Box py={theme.space["8"]}>
            <Box mb={theme.space["8"]}>
              <Box mb={theme.space["8"]}>
                <H1>Kontaktieren Sie uns</H1>
              </Box>
              <Box mb={theme.space["6"]}>
                <H4>WIR SIND GERN FÜR SIE DA.</H4>
              </Box>
              <Box>
                <Box mb={theme.space["4"]}>
                  Wir sind bei Fragen oder für Besichtigungstermine gerne für Sie erreichbar.
                </Box>
                <Box>Bitte beachten Sie, dass der Showroom nur mit telefonischer Voranmeldung besetzt ist</Box>
              </Box>
            </Box>
            <Box mb={theme.space["8"]}>
              <Box mb={theme.space["6"]}>
                <H4>PROJECT-E - DER SPEZIALIST FÜR TESLA</H4>
              </Box>
              <Box mb={theme.space["4"]}>Herferswil 60, Mettmenstetten, Schweiz</Box>
              <Box mb={theme.space["2"]}>
                Email:{" "}
                <a href="mailto:info@project-e.ch" style={{ color: theme.colors.red["800"] }}>
                  info@project-e.ch
                </a>
              </Box>
              <Box>
                Telefon:{" "}
                <a href="tel:+41 79 919 81 01" style={{ color: theme.colors.red["800"] }}>
                  +41 79 919 81 01
                </a>
              </Box>
            </Box>
            <Box>
              <Box mb={theme.space["6"]}>
                <H4>ÖFFNUNGSZEITEN</H4>
              </Box>
              <Flex mb={theme.space["2"]} onClick={() => setExpanded(!expanded)} sx={{ alignItems: "center" }}>
                <Box>Today:</Box>
                <Box mx={theme.space["2"]} color={theme.colors.red["800"]}>
                  {workingHours[(new Date().getDay() || 7) - 1]}
                </Box>
                <Flex>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Flex>
              </Flex>
              <Collapse in={expanded} timeout={"auto"}>
                {workingHours.map((workingHour, index) => (
                  <Box mb={theme.space["1"]} key={index}>
                    {daysOfWeekLong[index]}: {workingHour}
                  </Box>
                ))}
              </Collapse>
            </Box>
          </Box>
          <Box></Box>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};
