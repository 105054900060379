import { Typography } from "@mui/material";
import { Box, Grid } from "theme-ui";
import { theme } from "../../theme";
import { Button } from "../../components/Button/Button";

export const DoorHandleRepairModelS = () => {
  return (
    <Box>
      <Grid gap={[theme.space["4"], theme.space["6"], theme.space["12"]]} columns={["1fr", "1fr", "2fr 3fr"]}>
        <Box sx={{ flex: "0 0 40%" }}>
          <img src={"/images/services/door-handle.webp"} alt={""} style={{ width: "100%" }} />
        </Box>
        <Box>
          <Box color={theme.colors.red["600"]} mb={theme.space["1"]} sx={{ fontSize: "18px" }}>
            Tesla Model S
          </Box>
          <Box mb={theme.space["4"]}>
            <Typography variant="h5">TÜRGRIFF REPARIEREN</Typography>
          </Box>
          <Box>
            Bei den Vorfaceliftmodellen des Model S (bis Baujahr 2017) sind die Türgriffe eine der grossen
            Schwachstellen. Die Türgriffe fahren nicht mehr aus und Sie können die Türe nicht mehr von aussen öffnen -
            wir helfen Ihnen gerne.
          </Box>
          <Box my={theme.space["3"]}>
            Tesla wechselt den alten Türgriff gegen die neuste Generation (mit weniger Problemen) aus für CHF 400.- +.
            Wir reparieren Ihren alten Türgriff, das ist nicht nur günstiger, sondern auch nachhaltiger.
          </Box>
          <Box mb={theme.space["3"]}>
            Die Reparatur der Türgriffe kostet pauschal CHF 220.- und dauert etwa eine Stunde - Preis gilt für Türgriffe
            der ersten Generation (V1)
          </Box>
          <Box mb={theme.space["4"]}>
            Wir haben zudem auch Erfahrung in der Reparatur neuerer Türgriffe (V2 und sogar bereits V3) - die Preise
            dafür liegen zwischen CHF 250.- und CHF 350.-
          </Box>
          <Box>
            <Button variant="primary" size="large">Termin vereinbaren</Button>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};
