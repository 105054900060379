import { Card, CardContent, CardHeader, CardMedia } from "@mui/material";
import { Box } from "theme-ui";

export const Autopilot = () => {
  return (
    <Card>
      <CardHeader title={"Autopilot (bei tesla)"} />
      <CardMedia sx={{ paddingBottom: "60%", position: "relative" }}>
        <Box sx={{ position: "absolute", inset: 0 }}>
          <img
            src={"/images/tesla-terms/1.webp"}
            alt={""}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
      </CardMedia>
      <CardContent>
        <Box>
          Mit dem Wort Autopilot (kurz AP) wird die Selbstfahrfähigkeit Ihres Teslas Beschrieben. Hierbei wird in
          insgesamt vier Stufen unterschieden - von Autopilot 1.0, zu 2.0, 2.5 und 3.0. Mit einem Autopilot 1.0 kann ihr
          Fahrzeug selbständig die Spur halten und wechseln, den Abstand zum Vordermann regulieren und die
          Geschwindigkeit anpassen. Mit AP 3.0 fährt das Auto vollautonom (aktuell noch in der Beta)
        </Box>
      </CardContent>
    </Card>
  );
};
