import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Contact } from "./view/contact";
import { Home } from "./view/home/Home";
import { Imprint } from "./view/imprint";
import { RentTesla } from "./view/rent-tesla";
import { Services } from "./view/services";
import { UsefulInformation } from "./view/useful-information";
import { Vehicles } from "./view/vehicles/Vehicles";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/fahrzeugangebot" element={<Vehicles />} />
        <Route path="/dienstleistungen" element={<Services />} />
        <Route path="/tesla-mieten" element={<RentTesla />} />
        <Route path="/wissenswertes" element={<UsefulInformation />} />
        <Route path="/impressum" element={<Imprint />} />
        <Route path="/kontakt" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
