import { Typography } from "@mui/material";
import { Box, Grid } from "theme-ui";
import { theme } from "../../theme";
import { Button } from "../../components/Button/Button";

export const PowerliftUpgradeTrunk = () => {
  return (
    <Box>
      <Grid gap={[theme.space["4"], theme.space["6"], theme.space["12"]]} columns={["1fr", "1fr", "3fr 2fr"]}>
        <Box sx={{ order: [1, 1, 0] }}>
          <Box color={theme.colors.red["600"]} mb={theme.space["1"]} sx={{ fontSize: "18px" }}>
            Tesla Model 3
          </Box>
          <Box mb={theme.space["4"]}>
            <Typography variant="h5">POWERLIFT UPGRADE FRUNK / TRUNK</Typography>
          </Box>
          <Box>
            Bei der ersten Tesla Model 3 Generation lassen sich sowohl der hintere Kofferraum (Trunk) als auch der
            vordere Kofferraum (Frunk) zwar über die App / von aus dem Fahrzeug entriegeln, öffnen sich jedoch nicht
            automatisch.
          </Box>
          <Box my={theme.space["3"]}>Hier können wir Abhilfe schaffen!</Box>
          <Box mb={theme.space["3"]}>
            Mit dem Powerlift Upgrade statten wir Ihr Model 3 mit neuen Gasdruckfedern aus und der Frunk und oder Trunk
            öffnet sich in Zukunft auf Knopfdruck automatisch und mechanisch.
          </Box>
          <Box mb={theme.space["3"]}>
            Die Einbaukosten inklusive Material belaufen sich auf CHF 90.- pro Kofferraum oder CHF 150.- für Trunk und
            Frunk zusammen.
          </Box>
          <Box mb={theme.space["4"]}>Der Einbau dauert circa eine halbe Stunde.</Box>
          <Box>
            <Button variant="primary" size="large">Termin vereinbaren</Button>
          </Box>
        </Box>
        <Box sx={{ flex: "0 0 40%" }}>
          <img src={"/images/services/trunk-upgrade.webp"} alt={""} style={{ width: "100%" }} />
        </Box>
      </Grid>
    </Box>
  );
};
