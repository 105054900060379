import { Typography } from "@mui/material";
import { Box, Grid } from "theme-ui";
import { theme } from "../../theme";
import { Button } from "../../components/Button/Button";

export const InductionMobileCharging = () => {
  return (
    <Box>
      <Grid gap={[theme.space["4"], theme.space["6"], theme.space["12"]]} columns={["1fr", "1fr", "2fr 3fr"]}>
        <Box sx={{ flex: "0 0 40%" }}>
          <img src={"/images/services/mobile-charging.webp"} alt={""} style={{ width: "100%" }} />
        </Box>
        <Box>
          <Box color={theme.colors.red["600"]} mb={theme.space["1"]} sx={{ fontSize: "18px" }}>
            Tesla Model 3
          </Box>
          <Box mb={theme.space["4"]}>
            <Typography variant="h5">INDUKTIVES HANDYLADEPAD (TRI ZONE)</Typography>
          </Box>
          <Box mb={theme.space["3"]}>Kabelloses Handyladen auch im Vorfacelift Model 3?</Box>
          <Box mb={theme.space["3"]}>
            Wir machen es möglich. Mit unserem Tri-Zone Ladepad mit rutschfesten Gummimatten laden Sie zwei Geräte
            gleichzeitig mit einer schnellen Ladegeschwindigkeit.
          </Box>
          <Box mb={theme.space["3"]}>
            Unser Tri-Zone Ladepad können Sie für CHF 99.- kaufen und selbst installieren oder für CHF 120.- direkt bei
            uns installiert lassen.
          </Box>
          <Box mb={theme.space["4"]}>Das Ladepad stammt von RPM Tesla und entspricht somit auch hohen Qualitätsansprüchen.</Box>
          <Box>
            <Button variant="primary" size="large">Termin vereinbaren</Button>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};
