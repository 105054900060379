import { Box } from "theme-ui";
import { theme } from "../../theme";

export const TextOverlayImage = ({
  src,
  height = [66],
  brightness = 0.5,
  children,
}: {
  src: string;
  height?: number[];
  brightness?: number;
  children: React.ReactNode;
}) => {
  return (
    <Box sx={{ paddingBottom: height.map((height) => height + "%"), position: "relative" }}>
      <Box
        sx={{
          background: `url(${src}) no-repeat center / cover`,
          position: "absolute",
          inset: 0,
          filter: `brightness(${brightness})`,
        }}
      ></Box>
      <Box
        sx={{ position: "absolute", top: "50%", transform: "translateY(-50%)", width: "100%" }}
        color={theme.colors.white}
      >
        {children}
      </Box>
    </Box>
  );
};
