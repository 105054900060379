import { Card, CardContent, CardHeader, CardMedia, List, ListItem } from "@mui/material";
import { Box, Container, Flex, Grid } from "theme-ui";
import { Navigation } from "../../components/navigation/Navigation";
import { theme } from "../../theme";
import { H1 } from "../../components/typography";
import { Footer } from "../../components/footer/Footer";

export const Vehicles = () => {
  return (
    <Box>
      <Navigation />
      <Container sx={{ maxWidth: theme.sizes.container }} my={[theme.space["6"], theme.space["12"]]}>
        <Box sx={{ textAlign: "center" }} mb={[theme.space["6"], theme.space["12"]]}>
          <H1>UNSER FAHRZEUGANGEBOT - VIEL SPASS BEIM STÖBERN</H1>
        </Box>
        <Box>
          <Grid gap={3} columns={[1, 2, 3]}>
            <VehicleCard
              title="Tesla Model S"
              subheader="Die Oberklassenlimousine von Tesla - gebaut ab 2012"
              src="/images/tesla-s85d.webp"
              model="S 75D FACELIFT"
              price={41890}
            />
            <VehicleCard
              title="Tesla Model S"
              subheader="Die Oberklassenlimousine von Tesla - gebaut ab 2012"
              src="/images/tesla-s90d.webp"
              model="S 75D FACELIFT"
              price={41890}
            />
            <VehicleCard
              title="Tesla Model S"
              subheader="Die Oberklassenlimousine von Tesla - gebaut ab 2012"
              src="/images/tesla-s75d.webp"
              model="S 75D FACELIFT"
              price={41890}
            />
            <VehicleCard
              title="Tesla Model 3"
              subheader="Der erste Tesla für die Masse ab 2019"
              src="/images/tesla-s85d.webp"
              model="Model 3 standard range Plus"
              price={41890}
            />
          </Grid>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

const VehicleCard = ({
  title,
  subheader,
  src,
  model,
  price,
}: {
  title: string;
  subheader: string;
  src: string;
  model: string;
  price: number;
}) => {
  return (
    <Card variant="outlined">
      <CardHeader title={title} subheader={subheader} />
      <CardMedia sx={{ paddingBottom: "75%", position: "relative" }}>
        <Box sx={{ position: "absolute", inset: 0 }}>
          <img src={src} alt={title} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
        </Box>
      </CardMedia>
      <CardContent>
        <Flex sx={{ justifyContent: "space-between" }}>
          <Box>{model}</Box>
          <Box>CHF {price.toLocaleString("de-CH")}.-</Box>
        </Flex>
        <Box>
          <List>
            <ListItem sx={{ padding: "4px 0px" }}>- Baujahr 2017</ListItem>
            <ListItem sx={{ padding: "4px 0px" }}>- Aus erster Hand</ListItem>
            <ListItem sx={{ padding: "4px 0px" }}>- 8-fach bereift</ListItem>
            <ListItem sx={{ padding: "4px 0px" }}>- Vollausstattung</ListItem>
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};
