import { Card, CardContent, CardHeader, CardMedia } from "@mui/material";
import { Box } from "theme-ui";

export const TeslaServiceCenter = () => {
  return (
    <Card>
      <CardHeader title={"Tesla Service Center"} />
      <CardMedia sx={{ paddingBottom: "60%", position: "relative" }}>
        <Box sx={{ position: "absolute", inset: 0 }}>
          <img
            src={"/images/tesla-terms/3.webp"}
            alt={""}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
      </CardMedia>
      <CardContent>
        <Box>
          Die Servicecenter fungieren bei Tesla als Vertragswerkstätten und sind Ihr Ansprechpartner bei
          (Garantie-)Reparaturen und allen anderen Arbeiten rund um Ihren Tesla. Es wird dabei zwischen Servicecentern
          (mit Werkstatt) und Auslieferungscentern (meist nur mit Showroom) unterschieden. Erste führen Reparaturen
          durch, Auslieferungscenter führen Probefahrten und Auslieferungen von Neuwagen durch
        </Box>
      </CardContent>
    </Card>
  );
};
