import { PropsWithChildren } from "react";
import { useMediaQuery } from "react-responsive";

export const mobileOnly = () => "@media (max-width: 767px)";
export const tabletOnly = () => "@media (min-width: 768px) and (max-width: 992px)";
export const tabletDown = () => "@media (max-width: 991px)";
export const desktopUp = () => "@media (min-width: 992px)";

export const MobileOnly = ({ children }: PropsWithChildren) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return <>{isMobile ? children : null}</>;
};

export const TabletOnly = ({ children }: PropsWithChildren) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return <>{isTablet ? children : null}</>;
};

export const TabletDown = ({ children }: PropsWithChildren) => {
  const isTablet = useMediaQuery({ maxWidth: 991 });
  return <>{isTablet ? children : null}</>;
};

export const DesktopUp = ({ children }: PropsWithChildren) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return <>{isDesktop ? children : null}</>;
};
