import { Box } from "theme-ui";
import { Footer } from "../../components/footer/Footer";
import { Navigation } from "../../components/navigation/Navigation";

export const Imprint = () => {
  return (
    <Box>
      <Navigation />

      <Footer />
    </Box>
  );
};
