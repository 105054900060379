import styled from "styled-components";
import { theme } from "../../theme";
import { Button as ThemeButton } from "theme-ui";
import { ReactNode } from "react";

interface ButtonProps {
  variant?: "primary" | "secondary";
  children: ReactNode;
  size?: "small" | "medium" | "large";
}

export const Button = ({ children, variant = "primary", size = "small" }: ButtonProps) => {
  return (
    <StyledButton $variant={variant} $size={size}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(ThemeButton)<{ $variant: string; $size: string }>`
  color: ${theme.colors.white};
  transition: background 200ms ease-in-out;
  cursor: pointer;
  background: ${({ $variant }) =>
    ($variant === "primary" && theme.buttons.primary.bg) || ($variant === "secondary" && theme.buttons.secondary.bg)};
  &:hover {
    background: ${theme.buttons.primary.hover.bg};
  };
  padding: ${({ $size }) =>
    ($size === "medium" && "12px 24px") || ($size === "large" && "14px 28px")}!important;
  font-size: ${({ $size }) =>
    ($size === "medium" && "16px") || ($size === "large" && "18px")}!important;
`;
