import { Typography } from "@mui/material";
import { Box, Grid } from "theme-ui";
import { theme } from "../../theme";
import { Button } from "../../components/Button/Button";

export const LedTrunkLightning = () => {
  return (
    <Box>
      <Grid gap={[theme.space["4"], theme.space["6"], theme.space["12"]]} columns={["1fr", "1fr", "3fr 2fr"]}>
        <Box sx={{ order: [1, 1, 0] }}>
          <Box color={theme.colors.red["600"]} mb={theme.space["1"]} sx={{ fontSize: "18px" }}>
            Tesla Model 3
          </Box>
          <Box mb={theme.space["4"]}>
            <Typography variant="h5">UPGRADE LED TRUNKBELEUCHTUNG</Typography>
          </Box>
          <Box mb={theme.space["3"]}>
            Der Trunk (Kofferraum) des Model 3 ist wortwörtlich eine eher dunkle Angelegenheit - für CHF 130.- inklusive
            Arbeit beleuchten wir Ihren Trunk mit einem 60 Centimeter langen LED-Streifen und bringen so "Licht ins
            Dunkle".
          </Box>
          <Box mb={theme.space["4"]}>
            Das Upgrade erfolgt ohne grosse Eingriffe in Ihr Fahrzeug - somit gibt es auch keinerlei Garantieprobleme
            seitens Tesla.
          </Box>
          <Box>
            <Button variant="primary" size="large">Termin vereinbaren</Button>
          </Box>
        </Box>
        <Box sx={{ flex: "0 0 40%" }}>
          <img src={"/images/services/trunk-lightning.webp"} alt={""} style={{ width: "100%" }} />
        </Box>
      </Grid>
    </Box>
  );
};
