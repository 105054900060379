import { Box, Container, Flex } from "theme-ui";
import { theme } from "../../theme";

export const Footer = () => {
  return (
    <Box py={[theme.space["8"]]} bg={theme.colors.red["800"]} color={theme.colors.white}>
      <Container sx={{ maxWidth: theme.sizes.container }}>
        <Flex sx={{ fontSize: "14px", justifyContent: "space-between" }}>
          <Box>Copyright © 2023 Project-E - Der Spezialist für Tesla– Alle Rechte vorbehalten.</Box>
          <Box>Project-E GmbH, Herferswil 60, 8932 Mettmenstetten</Box>
        </Flex>
      </Container>
    </Box>
  );
};
