import { Typography } from "@mui/material";
import { Box, Grid } from "theme-ui";
import { theme } from "../../theme";
import { Button } from "../../components/Button/Button";

export const RearSpoiler = () => {
  return (
    <Box>
      <Grid gap={[theme.space["4"], theme.space["6"], theme.space["12"]]} columns={["1fr", "1fr", "2fr 3fr"]}>
        <Box sx={{ flex: "0 0 40%" }}>
          <img src={"/images/services/rear-spoiler.webp"} alt={""} style={{ width: "100%" }} />
        </Box>
        <Box>
          <Box color={theme.colors.red["600"]} mb={theme.space["1"]} sx={{ fontSize: "18px" }}>
            Tesla Model 3
          </Box>
          <Box mb={theme.space["4"]}>
            <Typography variant="h5">HECKSPOILER NACHRÜSTEN</Typography>
          </Box>
          <Box mb={theme.space["3"]}>Performanceoptik für Ihr normales Model 3 / Model 3 Longrange?</Box>
          <Box mb={theme.space["3"]}>
            Das geht mit unseren nachrüstbaren Heckspoilern bereits ab CHF 299.- (Maxton, ohne Montage) und je nach
            Modell auch bereits mit CH-Zulassung.
          </Box>
          <Box mb={theme.space["3"]}>
            Wir verkaufen sowohl das günsige Modell der Firma Maxon als auch das bekannte Modell von Startech (inklusive
            CH-Zulassung).
          </Box>
          <Box mb={theme.space["4"]}>Montage bei uns vor Ort möglich (dauert meist knapp 30 Minuten) für pauschal CHF 50.-</Box>
          <Box>
            <Button variant="primary" size="large">Termin vereinbaren</Button>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};
