import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();
  const prevLocation = useRef(location);

  useEffect(() => {
    if (prevLocation.current.pathname !== location.pathname) {
      window.scrollTo(0, 0);
    }
    prevLocation.current = location;
  }, [location]);

  return null;
};

export default ScrollToTop;
